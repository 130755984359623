<template>
  <div id="verifikasi-nasional-distribusi-send-doc-view">
    
    <div class="card card-custom border-lg border-0 border-primary">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/verifikasi/nasional/distribusi/send-doc"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              View Data
            </a>
          </li>
        </ul>
      </div>

      <!--begin::View Data Farm-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="mx-5" v-if="dataDistribusi != null">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-65 symbol-light d-sm-inline d-none mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-sm-3x svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <a class="text-dark-75 text-hover-primary mb-1 font-size-h5 font-weight-bolder">
                          {{ dataDistribusi.customer }} 
                        </a>
                        <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mb-1">
                              <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataDistribusi.kategori.uraian }}</span>
                            </div>
                        </div>
                        <div class="d-flex">
                          <b-badge :variant="dataDistribusi.sts.color">{{ dataDistribusi.sts.uraian }}</b-badge>
                        </div>
                    </div>
                    <!--end::Info-->

                </div>
                <!--end::Top-->

                <!--begin::Bottom-->
                <div class="pt-3">
                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-6 mb-4"></div>
                    <!--end::Separator-->

                    <!--begin::Description-->

                    <div class="border rounded p-3 mb-5">
                        <div class="d-flex align-items-center mx-3">
                            <div class="symbol symbol-35 symbol-light mr-4">
                                <span class="symbol-label">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Shopping/Box3.svg"/>
                                    <!--end::Svg Icon-->
                                </span> 
                                </span>
                            </div>         
                            <span class="text-dark-75 font-size-h5 font-weight-bolder">
                                <span class="mr-2">Tujuan Distribusi</span>
                            </span>
                        </div>
                        
                        <!--begin::Separator-->
                        <div class="separator separator-solid mt-3 mb-4"></div>
                        <!--end::Separator-->

                        <!--begin::Top-->
                        <div v-if="dataDistribusi.farm" class="d-flex align-items-center">
                            <!--begin::Info-->
                            <div class="d-flex flex-column flex-grow-1">
                                <div class="d-sm-flex d-block">
                                    <div class="d-flex align-items-center mr-sm-5">
                                        <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Home/Home.svg"/>
                                            <!--end::Svg Icon-->
                                        </span>                           
                                        <span class="font-weight-bold">Farm/Kandang {{ dataDistribusi.farm }}</span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Info-->
                        </div>
                        <!--end::Top-->

                        <!--begin::Bottom-->
                        <div class="pt-0">
                            <!--begin::Text-->
                            <div class="d-flex align-items-center pt-4 mb-2">
                                <span class="svg-icon svg-icon-lg svg-icon-primary mr-2">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                                    <!--end::Svg Icon-->
                                </span>                           
                                <span class="text-dark-75 font-size-lg font-weight-normal">
                                    Provinsi {{ dataDistribusi.provinsi.uraian }}, 
                                    {{ dataDistribusi.kabupaten.uraian }}, 
                                    Kecamatan {{ dataDistribusi.kecamatan.uraian }}, 
                                    Desa {{ dataDistribusi.desa.uraian }}
                                </span>
                            </div>
                            <!--end::Text-->

                            <!--begin::Separator-->
                            <div class="separator separator-solid mt-6 mb-4"></div>
                            <!--end::Separator-->

                            <!--begin::Description-->
                            <div class="border rounded p-3 mb-5">
                                <div class="d-flex align-items-center mx-3">
                                    <div class="symbol symbol-35 symbol-light mr-4">
                                        <span class="symbol-label">
                                            <span class="svg-icon svg-icon-md svg-icon-primary">
                                                <!--begin::Svg Icon-->
                                                <inline-svg src="media/svg/icons/General/Attachment2.svg"/>
                                                <!--end::Svg Icon-->
                                            </span> 
                                        </span>
                                    </div>         
                                    <span class="text-dark-75 font-size-h5 font-weight-bolder">
                                        Rincian
                                    </span>
                                </div>
                                
                                <!--begin::Separator-->
                                <div class="separator separator-solid mt-3 mb-4"></div>
                                <!--end::Separator-->

                                <b-row class="mx-sm-7 mx-2">
                                    <b-col v-for="(rinc, index) in dataDistribusi.rincian" :key="index" cols="12" sm="6" class="px-1">
                                        <div class="d-flex flex-column flex-grow-1">
                                            <span class="text-primary font-weight-bold">
                                                {{ rinc.sex }}
                                            </span>
                                            <span class="mb-2 text-muted font-weight-bold">
                                                {{ rinc.jumlah | nominal }} ekor
                                            </span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <!--end::Description-->
                        </div>
                        <!--end::Bottom-->
                    </div>

                    <div v-if="dataDistribusi.asaldoc.length > 0" class="border rounded p-3 mb-5">
                        <div class="d-flex align-items-center mx-3">
                            <div class="symbol symbol-35 symbol-light mr-4">
                                <span class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Commode1.svg"/>
                                        <!--end::Svg Icon-->
                                    </span> 
                                </span>
                            </div>         
                            <span class="text-dark-75 font-size-h5 font-weight-bolder">
                                Asal DOC
                            </span>
                        </div>
                        
                        <!--begin::Separator-->
                        <div class="separator separator-solid mt-3 mb-4"></div>
                        <!--end::Separator-->

                        <b-list-group>
                            <b-list-group-item v-for="(ush, index) in dataDistribusi.asaldoc" :key="index">Pullchick {{ ush.tgl_pullchick | dateIndonesia }} Hatchery {{ ush.settinghe.hatchery.nama }}</b-list-group-item>
                        </b-list-group>
                    </div>

                    <div class="border rounded p-3 mb-5">
                        <div class="d-flex align-items-center mx-3">
                            <div class="symbol symbol-35 symbol-light mr-4">
                                <span class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                    <!--begin::Svg Icon-->
                                    <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                                    <!--end::Svg Icon-->
                                    </span> 
                                </span>
                            </div>         
                            <span class="text-dark-75 font-size-h5 font-weight-bolder">
                                Map Lokasi
                            </span>
                        </div>
                      
                        <!--begin::Separator-->
                        <div class="separator separator-solid mt-3 mb-4"></div>
                        <!--end::Separator-->

                        <div class="rounded iframe-content">
                            <div id="gmaps" ref="goomaps"></div>
                        </div>
                    </div> 
                    <!--end::Description-->
                </div>
                <!--end::Bottom-->
              </div>

              <div v-else>
                <FacebookLoader></FacebookLoader>
              </div>

            </b-tab>
          </b-tabs>
        </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { FacebookLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VerifikasiService from "@/core/services/api/verifikasi/nasional/distribusi.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-nasional-distribusi-send-doc-view",
  props: {
    idS: {
      required: true
    }
  },
  components: {
    FacebookLoader
  },
  data() {
    return {
      tabIndex: 0,
      dataDistribusi: null,
      gooMaps: null,
      gooMarker: null,
    }
  },
  computed:{
    ...mapGetters(["currentUser"]),
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },

    getData () {
      VerifikasiService.getSendDocSingle({ _id : this.idS })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }

            this.dataDistribusi = { ...res.data.data, geotag: JSON.parse(res.data.data.geotag) }
            
            this.$nextTick(() => {
              setTimeout(() => {
                this.initMaps()              
              }, 500);
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    initMaps() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this.gooMaps = new google.maps.Map(this.$refs.goomaps, {
            center: this.dataDistribusi.geotag,
            zoom: 17,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },

          });
          this.gooMarker = new google.maps.Marker({ position: this.dataDistribusi.geotag, map: this.gooMaps });
        })
        .catch(err => {
          ErrorService.message({
            action: 'Peringatan',
            message: 'Maps gagal dimuat',
          })
        })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Nasional Send DOC" }]);
    if (!this.currentUser.menu.verifikasi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
<style lang="scss">
  #verifikasi-nasional-distribusi-send-doc-view {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps {
        height: 100%;
      }
    }
  }

</style>
